<template>
  <article class="container" property="offers" typeof="Offer">
    <template v-if="game.id">
    <div class="nomer-content"></div>
    <!-- <div class="nomer-image" v-bind:style="{ backgroundImage: 'url(' + game.feature_image + ')' }" >{{game.number}}</div> -->
    <meta property="schema:name" :content="game.number">
    <meta property="schema:description" :content="game.cell_operator">
    <div @click="goToPost()" class="nomer-image" style="font-family: Copse;color:#0000ff;font-size:2.7rem;text-shadow: 4px 4px 8px rgba(121, 255, 0, 1);padding: 50px 0px 20px 0px;white-space: nowrap;">{{viewNumber}}</div>
    <div class="nomer-title">{{contactIcon}}<a :href="contactHref">{{game.contact}}</a></div>
    <div class="nomer-stats">
        <div>
            <img class="stat-icon" src="../assets/calendar-alt-regular.svg" alt="Дата добавление">
            {{publishDate}}
        </div>
        <!-- <div>
            <img class="stat-icon" src="../assets/clock-regular.svg" alt="game length in minutes">
            {{game.stats.time[0]}}
            <span v-if="game.stats.time[1]">
                - {{game.stats.time[1]}}
            </span>
        </div>
        <div>
            <img class="stat-icon" src="../assets/user-friends-solid.svg" alt="number of players">
            {{game.stats.players[0]}}
            <span v-if="game.stats.players[1]">
                - {{game.stats.players[1]}}
            </span>
        </div> -->
    </div>
    <div class="nomer-price">
        <meta property="schema:priceCurrency" :content="currency">
        <meta property="schema:price" :content="game.price">
        <span> {{game.price}} {{currency}}</span>
        <!-- <span class="out-of-10" aria-label="out of 10">/10</span> -->
    </div>
    <div class="nomer-tags">
        <div>
            <img class="oper-icon" :src="operLogo" :alt="game.cell_operator" @click="goToOperator(game.cell_operator)">
        </div>
    </div>

    </template>
  </article>
</template>

<script>
export default {
    name: 'BlogPostCardLg',
    props: {
        game: Object,
    },
    methods: {
        goToPost() {
            this.$router.push({
                name: 'Number',
                params: {
                    number: this.game.number,
                },
            });
        },
        goToOperator() {
            // this.$router.push({
            //     name: 'Operator',
            //     params: {
            //         operatorName: operator,
            //     },
            // });
        },
    },
    computed: {
        publishDate() {
            const date = new Date(this.game.createddate * 1000).toLocaleDateString('en-US');
            return date;
        },
        viewNumber() {
            return this.game.number.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
        },
        operLogo() {
            const operApi = {
                s97: 'mobiuz',
                s88: 'mobiuz',
                s33: 'humans',
                s77: 'uzmobile',
                s99: 'uzmobile',
                s90: 'beeline',
                s91: 'beeline',
                s93: 'ucell',
                s94: 'ucell',
                s98: 'perfectum',
            };
            const logo = operApi[`s${this.game.number.substring(0, 2)}`];
            const images = require.context('../assets/', false, /\.svg$/);
            return images(`./${logo}.svg`);
        },
        currency() {
            return this.game.currency === 'USD' ? 'у.е.' : 'сўм';
        },
        contactIcon() {
            const first = this.game.contact.substring(0, 1);
            return first === '@' ? '💬' : '☎️';
        },
        contactHref() {
            const first = this.game.contact.substring(0, 1);
            const link = `https://t.me/${this.game.contact.substring(1)}`;
            return first === '@' ? link : `tel:${this.game.contact}`;
        },
    },
};
</script>

<style scoped>
    .container {
        display: grid;
        grid-template-rows: 120px auto 33px auto;
        grid-template-columns: 170px 110px;
        border-radius: 50px 50px 30px 10px;
        min-height: 250px;
        margin-bottom: 10%;
        /* box-shadow: 0 1px 2px rgba(0,0,0,0.07); */
    }

    .nomer-image {
        grid-area: 1/1/2/3;
        background-position: center top;
        border-radius: 50px 50px 0 0;
        border-bottom: 5px solid var(--primary-5);
    }

    .nomer-title {
        grid-area: 2/1/3/3;
        font-weight: bold;
        font-size: 20px;
        padding: 0 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 30px;
    }

    .nomer-title a {
        text-decoration: none;
        color: var(--grey-9);
    }

    .nomer-content {
        grid-area: 2/1/5/3;
    }

    .nomer-stats {
        grid-area: 3/1/4/2;
        padding-left: 20px;
        font-size: 16px;
    }

    .nomer-price {
        grid-area: 4/2/4/3;
        justify-self: end;
        padding-right: 20px;
        color: var(--primary-5);
        font-weight: bold;
        font-size: 20px;
    }

    .out-of-10 {
        color: var(--primary-5);
        font-weight: bold;
        font-size: 13px;
    }

    .nomer-tags {
        grid-area: 4/1/5/3;
        padding-left: 20px;
        padding-right: 20px;
        margin: 10px 0;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
    }

    .stat-icon {
        height: 15px;
        margin-right: 10px;
    }

    .oper-icon {
        height: 45px;
        margin-right: 10px;
    }

    .container:empty {
        box-shadow: none;
        background-repeat: no-repeat;
        background-image:
            linear-gradient(rgba(0,0,0,.1) 100%),
            linear-gradient(rgba(0,0,0,.1) 100%),
            linear-gradient(rgba(0,0,0,.1) 100%),
            linear-gradient(rgba(0,0,0,.1) 100%);
        background-size:
            100% 170px,
            70% 20px,
            30% 20px,
            50% 20px;
        background-position:
            0 0,
            20px 180px,
            20px 210px,
            20px 240px;
    }

@media only screen and (max-width: 600px) {
    .container {
        width: 100%;
        grid-template-rows: 40px 70px 40px;
        grid-template-columns: 150px auto 1fr;
        border-radius: 0;
        min-height: 0;
        margin-bottom: 2%;
        background: radial-gradient(at center, white, transparent);
    }

    .nomer-image {
        grid-area: 1/1/4/2;
        background-size: cover;
        border-bottom: none;
        border-radius: 0;
        font-size: 3.4rem!important;
        padding: 60px 15px 20px 10px !important;
    }

    .nomer-title {
        grid-area: 1/1/1/4;
        font-size: 1em;
        margin-top: 5px;
        padding-left: 20px;
        padding-right: 0;
    }

    .nomer-stats {
        grid-area: 1/4/1/4;
        font-size: 1em;
        padding-right: 20px;
        align-self: center;
    }

    .nomer-price {
        grid-area: 4/4/4/4;
        font-size: 1.5em;
        align-self: center;
        padding-right: 10px;
    }

    .nomer-tags {
        grid-area: 4/1/4/4;
        font-size: 1em;
        margin: 0;
        align-self: center;
        padding-left: 10px;
    }

    .out-of-10 {
        display: none;
    }

    .container:empty {
        background-image:
            linear-gradient(rgba(0,0,0,.1) 100%),
            linear-gradient(rgba(0,0,0,.1) 100%),
            linear-gradient(rgba(0,0,0,.1) 100%),
            linear-gradient(rgba(0,0,0,.1) 100%),
            linear-gradient(rgba(0,0,0,.1) 100%);
        background-size:
            150px 150px,
            50% 20px,
            25% 20px,
            30% 20px,
            40% 20px;
        background-position:
            0 0,
            160px 10px,
            160px 50px,
            160px 80px,
            160px 120px;
    }
}
</style>>
