import Vue from 'vue';
import Vuex from 'vuex';

import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    numbers: {
      records: [
        { title: '1', html: '1' },
        { title: '2', html: '2' },
        { title: '3', html: '3' },
      ],
    },
  },
  mutations: {
    updateGames(state, numbers) {
      state.numbers = numbers;
    },
  },
  actions: {
    async getGames({ commit }, payload) {
      const { currentPage, searchInput, operatorName } = payload;
      try {
        const result = await axios.get('https://nomer.uz/getall', {
          params: {
            _size: 10,
            _p: currentPage,
            _where: `(activeness,eq,1)${searchInput && searchInput !== '' ? `~and(number,like,~${searchInput}~)` : ''}${operatorName && operatorName !== '' ? `~and(cell_operator,eq,${operatorName})` : ''}`,
            _sort: '-category,-createddate',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _table_name: 'numbers',
            _zeTable: 'numbers',
            _use_prefix: true,
          },
        });
        /*
        const mappedResult = result.data.map((game) => {
          const stats = JSON.parse(game.meta_description);
          Object.defineProperty(game, 'stats', {
            value: stats,
            writable: true,
          });
          const tags = game.tags.map((tag) => {
            return tag.name;
          });
          Object.defineProperty(game, 'my_tags', {
            value: tags,
            writable: true,
          });
          return game;
        });
        */
        commit('updateGames', result.data);
      } catch (error) {
        console.error('Failed retrieving games', error);
      }
    },
  },
  modules: {
  },
});
