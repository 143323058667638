<template>
    <header>
        <nav class="main">
            <router-link to="/" style="display : flex" accesskey="h">
                <img class="logo_title" alt="Nomer.uz - продажа и покупка номеров" src="../assets/method-draw-image.svg" >
            </router-link>
            <a class="link" href="https://t.me/O1333AAA/"><h3>Продать Номер</h3></a>
        </nav>
    </header>
</template>

<script>
export default {
    name: 'MainHeader',
};
</script>

<style scoped>

.main {
    max-width: var(--max-page-width);
    padding: 0 20px;
    max-height: 100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    display: block;
    height: 50px;
    margin-right: 10px;
}

.logo_title {
    display: block;
    height: 35px;
    margin-top: 5px;
}

.link {
    text-decoration: none;
    color : inherit;
    font-family: Copse;
    color:#0000ff;
    text-shadow: 4px 4px 8px rgba(121, 255, 0, 1);
    padding-top: 5px;
}

.link.router-link-exact-active {
  color: var(--primary-5);
}

</style>
