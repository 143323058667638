<template>
  <div class="main">

    <section class="prefrences">
      <h1 style="font-family: Copse;color:#0000ff;text-shadow: 4px 4px 8px rgba(121, 255, 0, 1);padding-top: 5px;">Подберите себе оригинальный, красивый номер:</h1>

      <section class="search-container">
        <img src="../assets/search-icon.svg" alt="Поиск по номеру...">
        <input
          class="search" type="text" placeholder="Поиск по номеру..."
          v-model="search" aria-label="Поиск по номеру..."
          accesskey="s">
      </section>

      <section>
        <ul class="category-tags">
          <template v-for="(tag, index) in tags">
            <CategoryTag :title='tag' v-on:tagSelected="tagSelected" :key="index" />
          </template>
        </ul>
      </section>

    </section>

    <section vocab="https://schema.org/" typeof="ItemList">
      <link property="url" href="https://nomer.uz/"><span property="numberOfItems" style="display:none;">{{$store.state.numbers.total}}</span>
      <transition-group name="list-complete" tag="ul" class="posts-container">
        <li class="posts" v-for="(blogPost, index) in blogPosts" :key="blogPost.id" property="itemListElement" typeof="Product">
          <BlogPostCard tabindex="0" :game='blogPost' class="post"  :key="index"/>
        </li>
      </transition-group>
    </section>

    <section>
      <sliding-pagination
        class="posts-container"
        :current="currentPage"
        :total="totalPages"
        @page-change="pageChangeHandler"
      ></sliding-pagination>
    </section>

  </div>
</template>

<script>
// @ is an alias to /src
import BlogPostCard from '@/components/BlogPostCard.vue';
import CategoryTag from '@/components/CategoryTag.vue';
import SlidingPagination from 'vue-sliding-pagination';
// import axios from 'axios';

export default {
  name: 'Home',
  components: {
    BlogPostCard,
    CategoryTag,
    SlidingPagination,
  },
  data() {
    return {
      tags: ['Ucell', 'Beeline', 'Uzmobile', 'Mobiuz', 'Humans', 'Perfectum'],
      selectedTags: '',
      search: '',
      currentPage: 1,
    };
  },
  beforeMount() {
    this.checkRoute();
  },
  methods: {
    checkRoute() {
      let payload = {};
      if (this.$router.currentRoute.params.number) {
        this.search = this.$router.currentRoute.params.number;
        payload = {
          currentPage: 1,
          searchInput: this.search,
        };
        this.$store.dispatch('getGames', payload);
      } else if (this.$router.currentRoute.params.operatorName) {
        // console.log(this.$router.currentRoute.params.operatorName);
        const operator = this.$router.currentRoute.params.operatorName.toUpperCase();
        this.selectedTags = operator;
        payload = {
          currentPage: 1,
          searchInput: this.search,
          operatorName: operator,
        };
      } else {
        payload = {
          currentPage: 1,
        };
      }
      this.$store.dispatch('getGames', payload);
    },
    async pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
      const payload = {
        currentPage: selectedPage,
        searchInput: this.search,
        operatorName: this.selectedTags,
      };
      this.$store.dispatch('getGames', payload);
      window.scrollTo(0, 0);
    },
    tagSelected(title) {
      this.search = '';
      const operator = title.toUpperCase();
      const payload = {
          currentPage: 1,
          searchInput: this.search,
          operatorName: operator,
      };
      this.$store.dispatch('getGames', payload);
      // const index = this.selectedTags.indexOf(title);
      // if (index > -1) {
      //   this.selectedTags.splice(index, 1);
      // } else {
      //   this.selectedTags.push(title);
      // }
      this.selectedTags = operator;
    },
  },
  watch: {
    async search(input) {
      const payload = {
        currentPage: 1,
        searchInput: input,
        operatorName: this.selectedTags,
      };
      this.$store.dispatch('getGames', payload);
    },
    $route() {
      let payload = {};
      if (this.$router.currentRoute.params.number) {
        this.search = this.$router.currentRoute.params.number;
        payload = {
          currentPage: 1,
          searchInput: this.search,
        };
        this.$store.dispatch('getGames', payload);
      } else if (this.$router.currentRoute.params.operatorName) {
        // console.log(this.$router.currentRoute.params.operatorName);
        // const operator = this.$router.currentRoute.params.operatorName.toUpperCase();
        // this.selectedTags = operator;
        // payload = {
        //   currentPage: 1,
        //   searchInput: this.search,
        //   operatorName: operator,
        // };
      } else {
        this.search = '';
        payload = {
          currentPage: 1,
        };
        this.$store.dispatch('getGames', payload);
      }
    },
  },
  computed: {
    totalPages() {
      const { numbers } = this.$store.state;
      return Math.ceil(numbers.total / 10);
    },
    blogPosts() {
      const { numbers } = this.$store.state;
      return numbers.records;
      // if (!numbers.records[0].id) {
      //   return numbers.records;
      // }
      // return this.$store.state.numbers.records.filter((game) => {
      //   return game.number.toLowerCase().includes(this.search.toLowerCase());
      // }).filter((game) => {
      //   return this.selectedTags.length === 0
      //     || game.cell_operator.some((tag) => this.selectedTags.indexOf(tag) >= 0);
      // });
    },
  },

  metaInfo() {
        return {
            title: `${this.selectedTags !== '' ? `${this.selectedTags} ` : ''}${this.search !== '' ? `${this.search} ` : ''}Продажа и покупка номеров на Nomer.uz`,
            meta: [
              { name: 'yandex-verification', content: 'f53da8a7f351967f' },
              { name: 'title', content: `${this.selectedTags !== '' ? `${this.selectedTags} ` : ''}${this.search !== '' ? `${this.search} ` : ''}Продажа и покупка номеров на Nomer.uz` },
              { name: 'description', content: `${this.selectedTags !== '' ? `${this.selectedTags} ` : ''}${this.search !== '' ? `${this.search} ` : ''}Nomer.uz - Уникальный Веб портал для дилеров и покупателей телефонных номеров` },
              { name: 'keywords', content: `${this.search !== '' ? `${this.search}, ` : ''}${this.selectedTags !== '' ? `${this.selectedTags}, ` : ''}Номер, Nomer, Uzbekistan, UZ, Ucell, Beeline, Uzmobile, Perfectum, UMS, HUMANS, Mobiuz, Perfectum, номера, Уз, Узбекистан, номер, продажа, покупка, nomer, nomerlar, nomerlar sotib olish, nomerlar sotish, raqam tanlash, выбрать номер, nomer qidirish, nomer korish, продать "Золотой" номер, купить "Золотой" номер, продать номер, купить номер, оригинальный номер, красивый номер` },
              { name: 'robots', content: 'index, follow' },
              { name: 'language', content: 'Russian' },
            ],
        };
    },
};
</script>

<style scoped>

.main {
  padding: 0 20px;
}

.search-container {
  position: relative;
  width: 50%;
  min-width: 300px;
  margin: 0 auto;
}

.search-container img {
  position: absolute;
  top: 12px;
  left: 20px;
  height: 1.3em;
  z-index: 4;
}

.search {
  position: relative;
  color: var(--grey-8);
  padding: 8px 55px;
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
  width: 100%;
  font-size: 1em;
  outline: none;
  transition: outline .2s ease-out;

  border: double 4px transparent;
  border-radius: 18px;
  background-image: linear-gradient(white, white),
                    linear-gradient(60deg,
                    var(--primary-4), var(--primary-5), var(--primary-6),
                    var(--secondary-2), var(--secondary-3), var(--secondary-4));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-size: 200% 200%;
}

.search::placeholder {
  color: #A0AEC0;
}

.search:hover, .search:active, .search:focus {
  animation: animatedGradient 2s alternate ease infinite;
}

@keyframes animatedGradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.posts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-left: 0;
}

.posts {
  transition: all .4s ease-in-out;
}

.post {
    cursor: pointer;
    transition: all .2s ease-in-out;
}

li {
  list-style: none;
}

@media (hover: hover) {
  .post:hover {
      box-shadow: 0 25px 55px rgba(0,0,0,.2),
                  0 16px 28px rgba(0,0,0,.24);
      transform: scale(1.05);
  }
}

.post:focus {
  outline: none;
  box-shadow: 0 25px 55px rgba(0,0,0,.2),
                  0 16px 28px rgba(0,0,0,.24);
  transform: scale(1.05);
}

@media only screen and (max-width: 600px) {
  .posts {
    width: 100%;
    box-shadow: 0 25px 55px rgba(0,0,0,.2),
                0 16px 28px rgba(0,0,0,.24);
    transform: scale(1.05);
    margin-top: 20px;
    border-radius: 20px 20px 10px 10px;
  }
}

.prefrences {
  margin: 10px auto;
  text-align: center;
}

.category-tags {
  display: flex;
  flex-wrap: wrap;
  /* max-width: 400px; */
  margin: 10px auto;
  justify-content: center;
}

.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}

.list-complete-enter, .list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
}

</style>
