<template>
  <footer>
    <!-- <a href="https://www.youtube.com/channel/" target="_blank">
      <img class="youtube" src="../assets/youtube.png" alt="Nomer.uz">
    </a> -->
    <section class="social-media">
      <h2 style="font-family: Copse;color:#0000ff;text-shadow: 4px 4px 8px rgba(121, 255, 0, 1);padding-top: 5px;text-align: center;">Nomer.uz - предлагает возможность продажи и покупки любого номера по всем операторам связи Узбекистана</h2>
    </section>
    <section class="social-media">
      <a href="https://www.instagram.com/nomer.uz/" target="_blank">
        <img class="instagram" src="../assets/instagram.png" alt="Nomer.uz Instagram" >
      </a>
      <a href="https://t.me/nomer_uz/" target="_blank">
        <img class="telegram" src="../assets/telegram.png" alt="Nomer.uz Telegram" >
      </a>
      <a href="https://www.facebook.com/nomer.uz/" target="_blank">
        <img class="telegram" src="../assets/facebook.png" alt="Nomer.uz Facebook" >
      </a>
    </section>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
};
</script>

<style scoped>

.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media > a {
  margin-right: 15px;
}

.youtube {
  height: 30px;
}

.instagram {
  height: 30px;
}
.telegram {
  height: 30px;
}

</style>
