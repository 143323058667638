<template>
    <li>
    <button class="container"
        role="checkbox"
        :aria-checked="selected"
        :class="{'is-Selected' : selected}"
        @click="select()">
        {{title}}
    </button>

    </li>
</template>

<script>
export default {
    name: 'CategoryTag',
    data() {
        return {
            selected: false,
        };
    },
    props: {
        title: String,
    },
    computed: {

    },
    beforeMount() {
        this.checkRoute();
    },
    watch: {
        $route() {
            this.checkRoute();
        },
    },
    methods: {
        select() {
            this.selected = !this.selected;
            this.$emit('tagSelected', this.title);
            const title = this.title.toUpperCase();
            this.$router.push({ path: `/o/${title}` });
        },
        mouseDown(e) {
            if (e) {
                e.preventDefault();
            }
        },
        checkRoute() {
            // console.log(this.$router.currentRoute.params.operatorName);
            if (this.$router.currentRoute.params.operatorName) {
                const title = this.title.toUpperCase();
                const operatorName = this.$router.currentRoute.params.operatorName.toUpperCase();
                if (title === operatorName) {
                    this.selected = true;
                } else {
                    this.selected = false;
                }
            } else {
                this.selected = false;
            }
        },
    },
};
</script>

<style scoped>

li {
    list-style: none;
}

.container {
    display: flex;
    align-items: center;
    margin: 5px;
    padding: 9px 12px;
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    font-size: .7em;
    outline: none;
    transition: .2s ease-out;

    color: var(--grey-8);
    background: var(--grey-2);

}

@media (hover: hover) {
    .container:hover {
        color: #fff;
        background: linear-gradient(60deg, var(--primary-5), var(--secondary-3));
    }
}

.container:focus {
    box-shadow: 0 0 0 3pt rgba(169, 227, 125, .4);
}

.container:active {
    box-shadow: 0 0 0 4pt rgba(169, 227, 125, .6);
}

.is-Selected {
    color: #fff;
    background: linear-gradient(60deg, var(--primary-5), var(--secondary-3));
}

</style>
